<template>
  <div>
    <div class="participants-container">
      <div>
        <b-input-group size="sm" class="mb-2">
          <b-form-input
            v-model="search"
            id="member-search-field"
            type="search"
            placeholder="Search terms"
            debounce="400"
          ></b-form-input>
        </b-input-group>
        <div class="member-search-icon">
          <i class="fa fa-search" aria-hidden="true"></i>
        </div>
      </div>
      <!-- <div class="segments-div">  temporary commented 
        <b-badge
          v-for="{ id, name } in tags"
          :key="id"
          id="participants-tags"
           :class="selectedTags.includes(name)&& 'activeTagFieldSpan'"
          variant="dark"
          @click="search = name"
        >
          {{ name }}
        </b-badge>
      </div> -->
      <div class="selectBox">
        <b-form-select
          v-model="selected"
          @change="sortedUsers"
          size="sm"
          class="mt-3"
        >
          <option :value="null" class="disabledOption">Sort By</option>
          <option :value="1">Name:A-Z</option>
          <option :value="-1">Name:Z-A</option>
        </b-form-select>
      </div>
        <b-container v-if="event.display_participant_members" class="participants-photos">
          <b-row>
            <b-col
              class="participant-img member-card"
              sm="6"
              v-for="participant in filteredParticipants"
              :key="participant.id"
            >
              <div
                v-if="participant.details.customer_id !== ownerID && isMarkedAsSpam(participant) && !isMPBlocked"
                class="iconDiv"
                v-b-popover.hover.top="'Unblock from Spam'"
                @click="removeFromSpamList(participant)"
              >
                <i class="fas fa-info-circle"/>
              </div>
              <div
                v-else-if="participant.details.customer_id !== ownerID
                  && !isMemberMarkedMeAsSpam(participant)
                  && event.meeting_planner_is_active
                  && event.display_participants
                  && participant.display && !isMPBlocked"
                class="iconDiv"
                v-b-popover.hover.top="'Send Request'"
                @click="selectedUsers = [participant], showModal(true, {})"
              >
                <i class="fas fa-calendar-plus"/>
              </div>
              <b-row class="participant-photo-container">
                <img
                  class="moderator-photo"
                  :src="participant.details.prof_pic"
                  alt="user"
                />
                <div class="participant-info-container">
                  <router-link
                    :to="{
                      name: 'userProfilePage',
                      params: { customerId: getURL(participant.customer) }
                    }"
                  >
                    <p class="participant-username m-0 mt-2">
                      {{ participant.details.name }}
                    </p>
                  </router-link>
                  <p class="participant-position m-0">
                    {{ participant.details.job_title }}
                  </p>
                  <p class="participant-company m-0"
                  @click="goToCompany(participant.details.company_id)">
                    {{ participant.details.company }}
                  </p>
                </div>
              </b-row>
            </b-col>
          </b-row>

      <infinite-loading @infinite="infiniteHandler">
        <div slot="no-more" />
        <div slot="no-results" />
         <div slot="spinner"></div>
      </infinite-loading>
        </b-container>
    </div>
    <AvailableSlotsModal
      :handleClose="handleClose"
      :selectedUsers="selectedUsers"
      :fromParticipants="true"
      :users="users"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import getIDfromURL from '../../../utils/getIDfromURL';
import AvailableSlotsModal from '../../pages/MeetingPlanner/Modals/AvailableSlotsModal.vue';
import MPInvitationFunctional from '../../../utils/mixins/MPInvitationFunctional';
import { getOwnerId } from '../../../utils/getOwnerId';

export default {
  mixins: [MPInvitationFunctional],
  name: 'ParticipantMembers',
  components: {
    AvailableSlotsModal,
  },
  data() {
    return {
      selected: null,
      search: '',
      page: 1,
      users: [],
      hasNextPage: true,
      participantMembers: [],
      selectedTags: [],
      isMPBlocked: false,
    };
  },
  async mounted() {
    if (this.event.id) this.getMeetingsData();
    const participants = await this.getEventParticipantMember({ eventId: this.$route.params.eventId, customerId: this.userInfo.id });
    this.isMPBlocked = participants[0].is_mp_blocked;
  },
  methods: {
    ...mapActions({
      fetchParticipantMembers: 'events/fetchParticipantMembers',
      getEventParticipantMember: 'events/getEventParticipantMember',
    }),
    async infiniteHandler($state) {
      try {
        if (this.hasNextPage) {
          const data = await this.fetchParticipantMembers({
            eventId: this.$route.params.eventId,
            searchName: this.search,
            page: this.page,
          });
          if (data.results.length) {
            this.page += 1;
            this.hasNextPage = !!data.next;
            this.participantMembers = [...this.participantMembers, ...data.results];
            $state.loaded();
          } else {
            $state.complete();
          }
        } else {
          $state.complete();
        }
      } catch (err) {
        $state.complete();
      }
    },
    async getMeetingsData() {
      await this.fetchMeetingPlanner(this.event.id);
    },
    getURL(url) {
      return getIDfromURL(url);
    },
    sortedUsers(value) {
      const secondValue = value * -1;
      this.participantMembers.sort((a, b) => (
        a.details.name > b.details.name ? value : secondValue));
    },
    goToCompany(id) {
      if (id) {
        const companyId = `${id}`;
        this.$router.push({ name: 'companyProfile', params: { companyId } });
      }
    },
  },
  computed: {
    ...mapGetters({
      tags: 'tags/fetchItems',
      event: 'events/fetchEvent',
      userInfo: 'user/fetchUserInfo',
    }),
    ownerID() { return getOwnerId(); },
    filteredParticipants() {
      return this.participantMembers.filter((participant) => {
        return participant.invoice === null || participant.invoice && participant.invoice.status === 'paid';
      });
    },
  },
  watch: {
    search: {
      async handler(name) {
        let request = {};
        if (name) {
          request = {
            eventId: this.$route.params.eventId,
            searchName: name,
          };
        } else {
          this.page = 1;
          request = {
            eventId: this.$route.params.eventId,
            page: 1,
          };
        }
        const data = await this.fetchParticipantMembers(request);
        this.participantMembers = [...data.results];
        this.page += 1;
        this.hasNextPage = !!data.next;
      },
    },
    participantMembers: {
      deep: true,
      handler(members) {
        if (members.length) {
          this.users = members.filter(user => this.ownerID !== user.details.customer_id);
        }
      },
    },
    async event(newE, oldE) {
      if (newE.id !== oldE.id) this.getMeetingsData();
    },
  },
};
</script>
<style src="./Participants.css" scoped></style>
