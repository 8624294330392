import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import JsPDF from 'jspdf';
import domtoimage from 'dom-to-image';
import SingleMemberInvitation from './Modals/SingleMemberInvitation.vue';
import EditInvitation from './Modals/EditInvitation.vue';
import PreviewInvitationModal from './Modals/PreviewInvitationModal.vue';
import BlockSlot from './Modals/BlockSlot.vue';
import UnlockSlot from './Modals/UnlockSlot.vue';
import Calendar from './Calendar.vue';
import ParticipantMembers from '../../events/StandardEvent/ParticipantMembers.vue';
import ParticipantCompanies from '../../events/StandardEvent/ParticipantCompanies.vue';

export default {
  components: {
    SingleMemberInvitation,
    BlockSlot,
    EditInvitation,
    PreviewInvitationModal,
    UnlockSlot,
    Calendar,
    ParticipantMembers,
    ParticipantCompanies,
  },
  data() {
    return {
      slotTypes: ['pending', 'meeting', 'approved'],
      agendaTypes: {
        activity: 'Activity',
        panel_session: 'Panel Session',
      },
      showAttendees: false,
      showCompanies: false,
      isMPBlocked: false,
    };
  },

  methods: {
    showReceived(timeData) {
      let isReceivedRequest = false;
      if (timeData && timeData.invitation_request_details.length) {
        const requestedToIdsSet = new Set();
        const requestedByIdSet = new Set();
        timeData.invitation_request_details.forEach(
          ({ requested_to_id, requested_by_id }) => {
            requestedToIdsSet.add(requested_to_id);
            requestedByIdSet.add(requested_by_id);
          },
        );
        isReceivedRequest = requestedToIdsSet.has(this.userInfo.id)
          && !requestedByIdSet.has(requestedByIdSet)
          && timeData.slot_type === 'pending';
      }
      return isReceivedRequest;
    },
    showMultipleReceived(timeData) {
      let areMultipleRequests = false;
      if (timeData && timeData.invitation_request_details.length > 1) {
        const meetingIds = new Set();
        const requestedToIdsSet = new Set();
        timeData.invitation_request_details.forEach(
          ({ meeting_id, requested_to_id }) => {
            meetingIds.add(meeting_id);
            requestedToIdsSet.add(requested_to_id);
          },
        );
        areMultipleRequests = (!timeData.slot_type || timeData.slot_type === 'pending')
          && meetingIds.size > 1
          && requestedToIdsSet.size === 1
          && requestedToIdsSet.has(this.userInfo.id);
      }
      return areMultipleRequests;
    },
    showBlocked(timeData) {
      return timeData.slot_type === 'blocked';
    },
    showPending(timeData) {
      return timeData.slot_type === 'pending' || (timeData.invitation_request_details && timeData.invitation_request_details.length > 0 && 
        timeData.invitation_request_details.some(
          //  check is there is is_proceeded false
          ({ is_proceeded }) => !is_proceeded,
      ));
    },
    showMeeting(timeData) {
      return timeData.slot_type === 'meeting';
    },
    moment(date) {
      return moment(new Date(date).toUTCString());
    },
    eventMonths(start, end) {
      return (
        this.moment(start).format('MMM') === this.moment(end).format('MMM')
      );
    },
    eventDays(start, end) {
      let day = this.moment(start).format('DD');
      if (end && start !== end) {
        day = `${this.moment(start).format('DD')} - ${this.moment(end).format(
          'DD',
        )}`;
      }
      return day;
    },
    ...mapActions({
      fetchEvent: 'events/fetchEvent',
      fetchMeetings: 'meetingPlanner/fetchMeetings',
      fetchMeetingPlanner: 'meetingPlanner/fetchMeetingPlanner',
      fetchParticipantMembers: 'events/fetchParticipantMembers',
      fetchTags: 'tags/fetchList',
      getEventParticipantMember: 'events/getEventParticipantMember',
    }),
    printMP() {
      window.print();
    },
    downloadMP() {
      domtoimage
        .toPng(this.$refs.content)
        .then((dataUrl) => {
          const img = new Image();
          img.src = dataUrl;
          const doc = new JsPDF({
            orientation: 'portrait',
            format: [900, 1400],
          });
          doc.addImage(img, 'JPEG', 20, 20);

          doc.save();
        })
        .catch((error) => {
          console.error('something went wrong!', error);
        });
    },
    isExpired(day, time) {
      return new Date(`${day}, ${time}`).getTime() < new Date().getTime();
    },
  },

  computed: {
    ...mapGetters({
      userInfo: 'user/fetchUserInfo',
      event: 'events/fetchEvent',
      tags: 'tags/fetchItems',
      mpSlots: 'meetingPlanner/mpSlots',
      times: 'meetingPlanner/times',
      days: 'meetingPlanner/days',
      meetingDaysMap: 'meetingPlanner/meetingDaysMap',
      meetingDaysMapForMobile: 'meetingPlanner/meetingDaysMapForMobile',
    }),
  },
};
