<template>
  <div class="invite">
    <b-nav id="modalCalendar" pills>
      <b-nav-item-dropdown id="nav7_ddown" bottom>
        <template slot="button-content">
          <i id="icon-calendar" class="far fa-calendar-plus"></i
        ></template>
        <button class="apple" @click="iCalDownload">
          <div class="label">Apple Calendar</div>
        </button>
        <a class="google" :href="links.google" target="_blank">
          <div class="label">Google Calendar</div>
        </a>
        <a class="microsoft" :href="links.outlook" target="_blank">
          <div class="label">Microsoft Outlook</div>
        </a>
        <a class="yahoo" :href="links.yahoo" target="_blank">
          <div class="label">Yahoo! Calendar</div>
        </a>
      </b-nav-item-dropdown>
    </b-nav>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ct from 'countries-and-timezones';
import google from './calendarModules/google';
import outlook from './calendarModules/outlook';
import yahoo from './calendarModules/yahoo';

export default {
  data: () => ({
    event: {},
    links: {
      google: '',
      outlook: '',
      yahoo: '',
      ical: '',
    },
    timezones: [],
    timezone: '',
  }),

  mounted() {
    this.generateCalendars(this.eventData);
  },
  computed: {
    ...mapGetters({
      eventData: 'events/fetchEvent',
    }),
  },
  methods: {
    unurize(x) {
      return decodeURIComponent(x);
    },
    iCalDownload() {
      const { event, timezone, links } = this;
      const thisData = this;
      import('./calendarModules/ical').then((ical) => {
        links.ical = ical.default.call(thisData, event, timezone);
        import('downloadjs').then((download) => {
          download.default.call(
            thisData,
            links.ical,
            'invite.ics',
            'text/calendar',
          );
        });
      });
    },

    generateCalendars() {
      const tzones = ct.getAllTimezones();
      this.timezones = Object.keys(tzones);
      const { links } = this;
      this.event = {
        title: this.eventData.name,
        datetime: `${this.eventData.start_date}, 09:00:00`,
        location: this.eventData.city,
        duration: 30,
      };

      fetch('https://api.ipgeolocation.io/ipgeo?apiKey=14aadc9992e34663be5676388611b3a4')
        .then(response => response
          .json()
          .then((json) => {
            this.timezone = json.time_zone.name;
            links.google = google(this.event, this.timezone);
            links.outlook = outlook(this.event, this.timezone);
            links.yahoo = yahoo(this.event, this.timezone);
          })
          .catch((err) => { console.log('err-1', err); }))
        .catch((err) => { console.log('err-2', err); });
    },
  },
};
</script>
<style >
@media (max-width: 768px) {
.calendarBox .dropdown-menu   {
    border-radius: 0 10px 10px 10px;
    overflow: hidden;
  }
}
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: unset;
}
.calendarBox .dropdown-menu {
  width: 180px !important;
  padding: unset;
}

.calendarBox .dropdown-menu > li {
  font-size: 12px;
  border-bottom: 1px solid #BD9D5F;
  padding: 2px;
}
.calendarBox .dropdown-toggle {
  padding: unset;
}
.calendarBox .dropdown-toggle::after {
  display: none;
}
#icon-calendar {
  color: white;
  background-color: #BD9D5F;
  padding: 6px;
  outline: none;
  background-color: #BD9D5F;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  border-radius: 0 10px 10px 10px;
  height: 30px;
}
#icon-calendar:hover {
  background-color: white;
  color: #BD9D5F;
  transition: 0.5s;
  border: 1px solid;
}
.apple {
  width: 178px;
  height: 37px;
  font-size: 10px;
  padding: 0;
  border: unset;
  outline: none;
}
.label {
  background: white;
  font-size: 13px;
  height: 38px;
  color: black;
  text-align: center;
  line-height: 33px;
  border-bottom: 1px solid #BD9D5F;
}

.label:hover {
  background-color: #BD9D5F;
  color: white;
  transition: 0.4s;
  text-align: center;
}
</style>
