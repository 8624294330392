import moment from 'moment-timezone';

export default ({
  title, datetime, duration, location,
}, timezone) => {
  const day = moment.tz(
    parseInt(moment.tz(datetime, timezone).format('x'), 10),
    'UTC',
  );
  return `https://calendar.google.com/calendar/r/eventedit?text=${encodeURIComponent(
    title,
  )}&dates=${day.format('YYYYMMDD')}T${day.format('HHmmss')}Z/${day
    .add(duration, 'minutes')
    .format('YYYYMMDD')}T${day.format('HHmmss')}Z&location=${encodeURIComponent(
    location,
  )}&pli=1&sf=true&output=xml`;
};
