var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.days.length && _vm.timeData)?_c('div',{staticClass:"slot"},[(
      _vm.timeData.invitation_request_details[0].requested_by_id === _vm.userInfo.id
    )?_c('EditInvitation',{attrs:{"day":_vm.days[_vm.index],"timeData":_vm.timeData,"eventUrl":_vm.event.url},model:{value:(_vm.meetingDaysMap[_vm.time][_vm.index]),callback:function ($$v) {_vm.$set(_vm.meetingDaysMap[_vm.time], _vm.index, $$v)},expression:"meetingDaysMap[time][index]"}}):_vm._e(),_c('div',{staticClass:"imgBox"},[(_vm.timeData.invitation_request_details.length > 1)?_c('img',{attrs:{"src":require('@/assets/mpIcons/multiple-approved.png'),"alt":""}}):_c('img',{attrs:{"src":require('@/assets/mpIcons/single-approved.png'),"alt":""}})]),(_vm.timeData.invitation_request_details.length > 1)?_c('div',{staticClass:"txtBox"},[_c('span',[_vm._v("Meeting with multiple members")]),_c('p',{staticClass:"action-hint meeting"},[_vm._v("Click for more details")])]):_c('div',[(
        _vm.timeData.invitation_request_details[0].requested_by_id !== _vm.userInfo.id
      )?_c('div',{staticClass:"txtBox"},[_c('span',[_vm._v("Meeting with")]),_c('p',{staticClass:"userName"},[_vm._v("\n        "+_vm._s(_vm.timeData.invitation_request_details[0].requested_by_details.user
            .first_name)+"\n        "+_vm._s(_vm.timeData.invitation_request_details[0].requested_by_details.user
            .last_name)+"\n      ")]),_c('p',{staticClass:"userCompany"},[_vm._v("\n        "+_vm._s(_vm.timeData.invitation_request_details[0].requested_by_details.company
            .name)+"\n      ")]),_c('p',{staticClass:"userPosition"},[_vm._v("\n        "+_vm._s(_vm.timeData.invitation_request_details[0].requested_by_details
            .job_title)+"\n      ")])]):_c('div',{staticClass:"txtBox"},[_c('span',[_vm._v("Meeting with")]),_c('p',{staticClass:"userName"},[_vm._v("\n        "+_vm._s(_vm.timeData.invitation_request_details[0].requested_to_details.user
            .first_name)+"\n        "+_vm._s(_vm.timeData.invitation_request_details[0].requested_to_details.user
            .last_name)+"\n      ")]),_c('p',{staticClass:"userCompany"},[_vm._v("\n        "+_vm._s(_vm.timeData.invitation_request_details[0].requested_to_details.company
            .name)+"\n      ")]),_c('p',{staticClass:"userPosition"},[_vm._v("\n        "+_vm._s(_vm.timeData.invitation_request_details[0].requested_to_details
            .job_title)+"\n      ")])])])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }