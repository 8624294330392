<template>
  <div v-if="days.length && timeData" class="slot">
    <EditInvitation
      v-if="
        timeData.invitation_request_details[0].requested_by_id === userInfo.id
      "
      :day="days[index]"
      v-model="meetingDaysMap[time][index]"
      :timeData="timeData"
      :eventUrl="event.url"
    />
    <div class="imgBox">
      <img
        v-if="timeData.invitation_request_details.length > 1"
        :src="require('@/assets/mpIcons/multiple-approved.png')"
        alt=""
      />

      <img
        v-else
        :src="require('@/assets/mpIcons/single-approved.png')"
        alt=""
      />
    </div>

    <div class="txtBox" v-if="timeData.invitation_request_details.length > 1">
      <span>Meeting with multiple members</span>
      <p class="action-hint meeting">Click for more details</p>
    </div>

    <div v-else>
      <div
        v-if="
          timeData.invitation_request_details[0].requested_by_id !== userInfo.id
        "
        class="txtBox"
      >
        <span>Meeting with</span>
        <p class="userName">
          {{
            timeData.invitation_request_details[0].requested_by_details.user
              .first_name
          }}
          {{
            timeData.invitation_request_details[0].requested_by_details.user
              .last_name
          }}
        </p>
        <p class="userCompany">
          {{
            timeData.invitation_request_details[0].requested_by_details.company
              .name
          }}
        </p>
        <p class="userPosition">
          {{
            timeData.invitation_request_details[0].requested_by_details
              .job_title
          }}
        </p>
      </div>

      <div v-else class="txtBox">
        <span>Meeting with</span>
        <p class="userName">
          {{
            timeData.invitation_request_details[0].requested_to_details.user
              .first_name
          }}
          {{
            timeData.invitation_request_details[0].requested_to_details.user
              .last_name
          }}
        </p>
        <p class="userCompany">
          {{
            timeData.invitation_request_details[0].requested_to_details.company
              .name
          }}
        </p>
        <p class="userPosition">
          {{
            timeData.invitation_request_details[0].requested_to_details
              .job_title
          }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import MPFunctional from './MPFunctional';

export default {
  mixins: [MPFunctional],
  name: 'Meeting',
  props: {
    timeData: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: null,
    },
    time: {
      type: String,
      default: '',
    },
  },
};
</script>
<style scoped src="./css/slot.css"></style>
