var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"participants-container"},[_c('div',[_c('b-input-group',{staticClass:"mb-2",attrs:{"size":"sm"}},[_c('b-form-input',{attrs:{"id":"member-search-field","type":"search","placeholder":"Search terms","debounce":"400"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_vm._m(0)],1),_c('div',{staticClass:"selectBox"},[_c('b-form-select',{staticClass:"mt-3",attrs:{"size":"sm"},on:{"change":_vm.sortedUsers},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('option',{staticClass:"disabledOption",domProps:{"value":null}},[_vm._v("Sort By")]),_c('option',{domProps:{"value":1}},[_vm._v("Name:A-Z")]),_c('option',{domProps:{"value":-1}},[_vm._v("Name:Z-A")])])],1),(_vm.event.display_participant_members)?_c('b-container',{staticClass:"participants-photos"},[_c('b-row',_vm._l((_vm.filteredParticipants),function(participant){return _c('b-col',{key:participant.id,staticClass:"participant-img member-card",attrs:{"sm":"6"}},[(participant.details.customer_id !== _vm.ownerID && _vm.isMarkedAsSpam(participant) && !_vm.isMPBlocked)?_c('div',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Unblock from Spam'),expression:"'Unblock from Spam'",modifiers:{"hover":true,"top":true}}],staticClass:"iconDiv",on:{"click":function($event){return _vm.removeFromSpamList(participant)}}},[_c('i',{staticClass:"fas fa-info-circle"})]):(participant.details.customer_id !== _vm.ownerID
                && !_vm.isMemberMarkedMeAsSpam(participant)
                && _vm.event.meeting_planner_is_active
                && _vm.event.display_participants
                && participant.display && !_vm.isMPBlocked)?_c('div',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Send Request'),expression:"'Send Request'",modifiers:{"hover":true,"top":true}}],staticClass:"iconDiv",on:{"click":function($event){_vm.selectedUsers = [participant], _vm.showModal(true, {})}}},[_c('i',{staticClass:"fas fa-calendar-plus"})]):_vm._e(),_c('b-row',{staticClass:"participant-photo-container"},[_c('img',{staticClass:"moderator-photo",attrs:{"src":participant.details.prof_pic,"alt":"user"}}),_c('div',{staticClass:"participant-info-container"},[_c('router-link',{attrs:{"to":{
                    name: 'userProfilePage',
                    params: { customerId: _vm.getURL(participant.customer) }
                  }}},[_c('p',{staticClass:"participant-username m-0 mt-2"},[_vm._v("\n                    "+_vm._s(participant.details.name)+"\n                  ")])]),_c('p',{staticClass:"participant-position m-0"},[_vm._v("\n                  "+_vm._s(participant.details.job_title)+"\n                ")]),_c('p',{staticClass:"participant-company m-0",on:{"click":function($event){return _vm.goToCompany(participant.details.company_id)}}},[_vm._v("\n                  "+_vm._s(participant.details.company)+"\n                ")])],1)])],1)}),1),_c('infinite-loading',{on:{"infinite":_vm.infiniteHandler}},[_c('div',{attrs:{"slot":"no-more"},slot:"no-more"}),_c('div',{attrs:{"slot":"no-results"},slot:"no-results"}),_c('div',{attrs:{"slot":"spinner"},slot:"spinner"})])],1):_vm._e()],1),_c('AvailableSlotsModal',{attrs:{"handleClose":_vm.handleClose,"selectedUsers":_vm.selectedUsers,"fromParticipants":true,"users":_vm.users}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"member-search-icon"},[_c('i',{staticClass:"fa fa-search",attrs:{"aria-hidden":"true"}})])}]

export { render, staticRenderFns }