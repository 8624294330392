<template>
  <div class="blockSlot">
    <i @click="modalShow = !modalShow" class="fas fa-lock" title="Block"></i>
    <b-modal v-model="modalShow" hide-footer="">
      <div class="blockMsg">
        <h2 class="blockTxt">Do you want to block this slot ?</h2>
      </div>
      <div class="footer">
        <span class="support">
          <a
            class="privacy-settings-support-text"
            href="mailto:info@carriercommunity.com?subject=Support&cc=Kara@carriercommunity.com;w.schmidt@carriercommunity.com"
          > Need support? Click here!
          </a>
        </span>
        <button class="confirmButton" @click.prevent="showModal">
          Submit
        </button>
        <button class="cancelButton" @click="modalShow = !modalShow">
          Cancel
        </button>
      </div>
    </b-modal>
    <div>
      <b-modal ref="block-modal" hide-footer>
        <div class="congrat">
          <p>CONGRATULATIONS!</p>
          <p>Your Meeting slot has been successfully locked.</p>
        </div>
        <div style="height:7vh; width:100% ; border-top:1px solid #eee"></div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    timeData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      modalShow: false,
      congratModal: false,
    };
  },

  methods: {
    ...mapActions({
      meetingPlannerSlotBlock: 'meetingPlanner/meetingPlannerSlotBlock',
    }),
    async showModal() {
      this.modalShow = false;
      const myData = {
        slot: this.value.slot_type ? this.value.slot : this.value.id,
      };
      try {
        const data = await this.meetingPlannerSlotBlock(myData);
        const blockData = {
          ...this.timeData,
          customer_meeting_planner_id: data.id,
          slot_type: 'blocked',
        };

        this.$emit('input', blockData);
        this.$refs['block-modal'].show();
      } catch (err) {
        console.log(err);
      }
    },
    hideModal() {
      this.$refs['block-modal'].hide();
    },
  },

};
</script>

<style src="./modals.css" scoped></style>
<style src="./StylesForMobile.css" scoped></style>
<style>
.blockSlot > i {
  font-size: 11px;
  color: #ebebeb;
  position: absolute;
  padding: 2px;
  top: 0px;
  right: 0px;
  cursor: pointer;
}
.blockMsg > .blockTxt {
  font-size: 19px;
  text-align: center;
  line-height: 127px;
  color: gray;
}
@media (max-width: 330px) { 
  .blockSlot, .blockSlot > i {
    position: relative !important;
  }
  .blockSlot > i {
    left:29%;
  }
}
@media (max-width: 768px) {
  .blockSlot > i {
    right: 8px;
    top: 12px;
    background-color: #f3f3f3;
    padding: 8px;
    color: #bd9d5f;
    border-radius: 50%;
  }
}
</style>
