<template>
  <div class="preview-invitation-outer" v-if="!$lodash.isEmpty(timeData)">
    <i
      @click="showPreview"
      class="fas fa-info-circle previewInvitationIcon"
      title="Open"
    />
    <b-modal hide-footer :id="`${timeData.id}`">
      <div class="slot-day">
        <h4>{{ moment(date).format("dddd DD MMMM") }}</h4>
      </div>
      <div class="editMeetingDuration">
        <p class="time">{{ startTime }} - {{ endTime }}</p>
      </div>
      <div class="editUserInfo">
        <b-container fluid class="meeting-members-container">
          <div
            v-for="invitation in timeData.participants"
            :key="invitation.id"
            class="meeting-members"
          >
            <div class="imgBox p-0">
              <img
                v-if="userId !== invitation.requested_to_id"
                width="100%"
                height="100%"
                :src="invitation.requested_to_details.profile_picture"
                alt=""
                :class="!invitation.approved && 'pending-user-img'"
              />
              <img
                v-else
                width="100%"
                height="100%"
                :src="invitation.requested_by_details.profile_picture"
                alt=""
                :class="!invitation.approved && 'pending-user-img'"
              />
              <i
                v-if="!invitation.approved && !invitation.is_proceeded"
                class="far fa-clock pending-user-icon"
              />
              <i
                v-if="!invitation.approved && invitation.is_proceeded"
                class="fas fa-ban declined-user-icon"
              />
            </div>
            <div class="textBox" v-if="userId !== invitation.requested_to_id">
              <router-link
                :to="{
                  name: 'userProfilePage',
                  params: {
                    customerId: invitation.requested_to_id,
                  },
                }"
              >
                <p class="editUserName">
                  {{ invitation.requested_to_details.user.first_name }}
                  {{ invitation.requested_to_details.user.last_name }}
                </p>
              </router-link>
              <p class="editUserPosition">
                {{ invitation.requested_to_details.job_title }}
              </p>
              <router-link
                :to="{
                  name: 'companyProfile',
                  params: {
                    companyId: invitation.requested_to_details.company_id,
                  },
                }"
              >
                <p class="editUserCompany mb-0">
                  {{ invitation.requested_to_details.company.name }}
                </p>
              </router-link>
            </div>
            <div class="textBox" v-else>
              <router-link
                :to="{
                  name: 'userProfilePage',
                  params: { customerId: invitation.requested_by_id },
                }"
              >
                <p class="editUserName">
                  {{ invitation.requested_by_details.user.first_name }}
                  {{ invitation.requested_by_details.user.last_name }}
                </p>
              </router-link>
              <p class="editUserPosition">
                {{ invitation.requested_by_details.job_title }}
              </p>
              <router-link
                :to="{
                  name: 'companyProfile',
                  params: {
                    companyId: invitation.requested_by_details.company_id,
                  },
                }"
              >
                <p class="editUserCompany mb-0">
                  {{ invitation.requested_by_details.company.name }}
                </p>
              </router-link>
            </div>
            <div class="actions">
              <i
                class="fa fa-envelope"
                @click="sendMsg(invitation.requested_to_details)"
                v-if="userId !== invitation.requested_to_id"
              ></i>
              <i
                class="fa fa-envelope"
                @click="sendMsg(invitation.requested_by_details)"
                v-else
              ></i>
            </div>
          </div>
        </b-container>
      </div>
      <div>
        <div
          class="messageSection"
          v-if="timeData.invitation_request_details[0].meeting_details.message"
        >
          <h3>Meeting Request Message</h3>
          <p>
            {{ timeData.invitation_request_details[0].meeting_details.message }}
          </p>
        </div>
        <div
          class="messageSection"
          v-if="timeData.invitation_request_details[0].meeting_details.location"
        >
          <h3>Meeting Location</h3>
          <p>
            {{
              timeData.invitation_request_details[0].meeting_details.location
            }}
          </p>
        </div>
      </div>
      <div class="forStatus">
        <div v-if="timeData">
          <h5 class="statusText">Status</h5>
          <p :class="`status-${slotTypes[timeData.slot_type]}`">
            {{ slotTypes[timeData.slot_type] }}
          </p>
        </div>
      </div>

      <div v-if="showButtons">
        <div class="button-box">
          <b-button
            variant="outline-success"
            @click="acceptInvite(timeData.invitation_request_details[0].id, 0)"
          >
            Accept
          </b-button>
          <b-button
            variant="outline-danger"
            @click="rejectInvite(timeData.invitation_request_details[0])"
          >
            Refuse
          </b-button>
          <b-button
            variant="outline-warning"
            @click="rescheduleInvite(timeData.invitation_request_details[0])"
          >
            Refuse and reschedule
          </b-button>
        </div>
        <b-modal
          id="mpCongratModal"
          hide-footer
          @hidden="() => onModalHide(timeData.id)"
        >
          <div class="congrat">
            <p>CONGRATULATIONS!</p>
            <p>Your Meeting has been successfully accepted.</p>
          </div>
          <div
            style="height: 7vh; width: 100%; border-top: 1px solid #eee"
          ></div>
        </b-modal>
        <b-modal
          id="mpRefuseModal"
          hide-footer
          size="lg"
          @hidden="() => onModalHide(timeData.id)"
        >
          <div class="congrat">
            <p class="refuse-title">The meeting request has been successfully refused.</p>
            <p class="refuse-content">
              Optionally you can explain to the inviting Member the reason of
              your refusal.
            </p>
          </div>
          <div style="width:100%; padding: 0 10px; text-align: right;">
            <b-textarea no-resize v-model="refusalMessage"></b-textarea>
            <b-button @click="sendMessage" variant="info" class="mt-3">
              Send
            </b-button>
          </div>
          <hr />
          <div class="congrat">
            <p>Annoyed by this invitation?</p>
            <p>
              You can mark the inviting Member as spam. <br />
              This will prevent to receive further meeting invitations for this
              event.
            </p>
            <b-button
              variant="outline-danger"
              @click="
                addToSpamList(
                  timeData.invitation_request_details[0].requested_by_details
                )
              "
            >
              MARK AS SPAM
            </b-button>
          </div>
        </b-modal>
      </div>
    </b-modal>
    <AvailableSlotsModal
      :handleClose="handleClose"
      :selectedUsers="selectedUsers"
      :fromParticipants="true"
      :users="users"
      :timeData="timeData"
    />
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import errorHelper from '../../../../utils/errorHelper';
import AvailableSlotsModal from './AvailableSlotsModal.vue';

export default {
  components: { AvailableSlotsModal },
  props: {
    timeData: {
      type: Object,
      default: () => ({ invitation_request_details: [] }),
    },
    date: {
      type: String,
      default: '',
    },
    userId: {
      type: Number,
      default: null,
    },
    dayIndex: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      slotTypes: {
        meeting: 'Confirmed',
        pending: 'Pending',
      },
      selectedUsers: [],
      users: [],
      refusalMessage: '',
      selectedInvitation: null,
    };
  },

  computed: {
    ...mapGetters({
      userInfo: 'user/fetchUserInfo',
      event: 'events/fetchEvent',
      availableSlotsModalId: 'meetingPlanner/availableSlotsModalId',
    }),
    showButtons() {
      return (
        this.timeData.invitation_request_details.length === 1
        && this.timeData.invitation_request_details[0].requested_to_id
          === this.userInfo.id
        && !this.timeData.invitation_request_details[0].is_proceeded
      );
    },
    startTime() {
      const invitatons = this.timeData.invitation_request_details;
      if (invitatons.length && invitatons[0].meeting_planner_slots.length) {
        invitatons[0].meeting_planner_slots.sort((a, b) => a.id - b.id);
        return invitatons[0].meeting_planner_slots[0].start.slice(0, 5);
      }
      return this.timeData.start.slice(0, 5);
    },
    endTime() {
      const invitatons = this.timeData.invitation_request_details;
      if (invitatons.length && invitatons[0].meeting_planner_slots.length) {
        return invitatons[0].meeting_planner_slots[
          invitatons[0].meeting_planner_slots.length - 1
        ].end.slice(0, 5);
      }
      return this.timeData.end.slice(0, 5);
    },
  },
  mounted() {
    const invitatons = this.timeData.invitation_request_details;
    this.selectedUsers = invitatons.map(invitation => ({
      details: {
        prof_pic: invitation.requested_by_details.profile_picture,
        name: `${invitation.requested_by_details.user.first_name} ${invitation.requested_by_details.user.last_name}`,
        job_title: invitation.requested_by_details.job_title,
        company: invitation.requested_by_details.company.name,
        url: `${process.env.VUE_APP_API_HOST}/customers/${invitation.requested_by_id}/`,
      },
    }));
  },
  methods: {
    ...mapActions({
      acceptOrRefuseInvitation: 'meetingPlanner/acceptOrRefuseInvitation',
      fetchMeetingPlanner: 'meetingPlanner/fetchMeetingPlanner',
      fetchMeetings: 'meetingPlanner/fetchMeetings',
      setAvailableSlotsModalId: 'meetingPlanner/setAvailableSlotsModalId',
      addRemoveSpam: 'user/addRemoveSpam',
      createNewConversation: 'messages/createNewConversation',
      sendMessageViaNodeJSServer: 'messages/sendMessageViaNodeJSServer',
    }),
    handleClose() {
      this.$bvModal.hide(`available-modal-${this.availableSlotsModalId}`);
      this.confirmModalShow = false;
    },
    async sendMsg(user) {
      try {
        const { result } = await this.createNewConversation({
          receiver_id: user.user_id,
        });
        this.$router.push({
          name: 'currentMessage',
          params: {
            chatRoomId: result.chat_room_id,
            user,
          },
        });
      } catch (err) {
        console.log(err);
      }
    },
    async acceptInvite(id, invitationIndex) {
      try {
        await this.acceptOrRefuseInvitation({
          invitationResponse: { id, approved: true },
          startTime: `${this.startTime}:00`,
          endTime: `${this.endTime}:00`,
          dayIndex: this.dayIndex,
          invitationIndex,
        });
        this.$bvModal.show('mpCongratModal');
      } catch (err) {
        console.log(err);

        errorHelper(err.response.data).forEach((message) => {
          this.$notify({
            group: 'userAlerts',
            title: 'Error',
            text: message,
            type: 'error',
          });
        });
      }
    },
    onModalHide(timeDataId) {
      this.$bvModal.hide(`${timeDataId}`);
      this.fetchMeetingPlanner(this.$route.params.eventId);
      this.selectedInvitation = null;
    },
    async rejectInvite(invite) {
      try {
        this.selectedInvitation = invite;
        await this.acceptOrRefuseInvitation({
          invitationResponse: { id: invite.id, approved: false },
          startTime: `${this.startTime}:00`,
          endTime: `${this.endTime}:00`,
          dayIndex: this.dayIndex,
          invitationIndex: null,
        });
        this.$bvModal.show('mpRefuseModal');
      } catch (err) {
        errorHelper(err.response.data).forEach((message) => {
          this.$notify({
            group: 'userAlerts',
            title: 'Error',
            text: message,
            type: 'error',
          });
        });
      }
    },
    async rescheduleInvite(invitation) {
      await this.acceptOrRefuseInvitation({
        invitationResponse: { id: invitation.id, approved: false },
        startTime: `${this.startTime}:00`,
        endTime: `${this.endTime}:00`,
        dayIndex: this.dayIndex,
        invitationIndex: null,
      });
      await this.fetchMeetings({
        event: this.event.url,
        opposite_customers: this.selectedUsers.map(
          ({ details: { url } }) => url,
        ),
        check_customer: true,
      });
      this.$bvModal.show(`available-modal-${this.timeData.id}`);
      await this.setAvailableSlotsModalId(this.timeData.id);
    },
    async addToSpamList({ id }) {
      try {
        await this.addRemoveSpam({
          customer: `${process.env.VUE_APP_API_HOST}/customers/${id}/`,
          action: 'add',
          customer_id: id,
        });
        this.$bvModal.hide('mpRefuseModal');
      } catch (err) {
        errorHelper(err.response.data).forEach((message) => {
          this.$notify({
            group: 'userAlerts',
            title: 'Error',
            text: message,
            type: 'error',
          });
        });
      }
    },
    moment(date) {
      return moment(new Date(date).toUTCString());
    },
    async showPreview() {
      this.$bvModal.show(`${this.timeData.id}`);
    },
    async sendMessage() {
      if (!this.refusalMessage) return;
      try {
        const { result } = await this.createNewConversation({
          receiver_id: this.selectedInvitation.requested_by_details.user_id,
        });
        const messageData = {
          chat_room_id: result.chat_room_id,
          content: this.refusalMessage,
          receiver_id: this.selectedInvitation.requested_by_details.user_id,
          is_online_receiver: false,
        };
        this.sendMessageViaNodeJSServer(messageData);
        this.refusalMessage = '';
        this.$notify({
            group: 'userAlerts',
            title: 'Success',
            text: 'Message sent successfully!',
            type: 'success',
        });
      } catch (err) {
        this.$notify({
          group: 'userAlerts',
          title: 'Warning',
          text: err,
          type: 'warn',
        });
      }
    },
  },
};
</script>


<style src="./SingleMemberInvitation.css" scoped></style>
<style src="./modals.css" scoped></style>
<style src="./StylesForMobile.css" scoped></style>
<style scoped>
.button-box > button {
  height: 30px;
  padding: 3px 5px;
  border-radius: unset;
}
.button-box {
  border-top: 1px solid #ebebeb;
  padding: 5px;
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;
}
</style>

<style>
@media (max-width: 375px) {
  .preview-invitation-outer {
    padding: 10px 0;
  }
  i.previewInvitationIcon {
    position: relative !important;
    left: 26% !important;
    padding: 7px !important;
  }
  #meetingPlanner .fa-info-circle {
    top: 3px !important;
  }
}
.previewInvitationIcon {
  font-size: 11px !important;
  color: #bd9d5f;
  position: absolute;
  padding: 2px;
  top: 0 !important;
  right: 0;
  cursor: pointer;
  z-index: 1;
  transition-duration: 0.2s;
}
.previewInvitationIcon:hover {
  color: #fff;
  background-color: #bd9d5f !important;
}
.editUserInfo {
  padding: 5px 12px;
  border-bottom: 5px solid #ebebeb;
  border-top: 5px solid #ebebeb;
}

.textBox {
  line-height: 12px;
  padding-bottom: 0px;
  padding-top: 1px;
  font-size: 13px;
}
.textBox p {
  margin: 2px;
}
.editUserName {
  text-transform: capitalize;
  font-weight: bold;
  color: #585555;
}
.editUserPosition {
  font-size: 11px;
  text-transform: capitalize;
  color: gray;
}
.editUserCompany {
  font-size: 11px;
  text-transform: capitalize;
  color: #bd9d5f;
}
.modal-body {
  padding: 0 !important;
}
.forStatus {
  padding: 6px 15px 1px 16px;
}
.statusText {
  font-size: 18px;
  color: gray;
}
.status-Confirmed {
  color: #5cb85c;
  font-size: 13px;
}
.status-Pending {
  color: #bd9d5f;
  font-size: 13px;
}
.editMeetingDuration {
  padding: 0px 0px 6px 14px;
  margin-top: -28px !important;
}
.messageSection {
  margin-top: 4px;
  margin-bottom: 5px;
  padding: 13px;
  border-bottom: 6px solid #ebebeb;
}
.messageSection > h3 {
  color: gray;
  font-size: 20px;
  font-weight: 600;
}
.messageSection > p {
  color: rgb(41, 41, 41);
  font-size: 15px;
}
.actions {
  position: absolute;
  right: 0;
  top: -2px;
}
.refuse-title {
  color: #999999;
}
.refuse-content {
 color: rgb(224, 62, 45) !important;
}
</style>
