<template>
  <div>
    <button
      @click="openInvitationModal()"
      style="cursor: pointer"
      class="forOpenModal"
    >
      <div class="request-meeting-text">Request a Meeting</div>
    </button>
    <b-modal hide-footer id="allModals" size="lg" v-model="confirmModalShow">
      <div class="slot-day">
        <h4>{{ moment(day).format("dddd DD MMMM") }}</h4>
      </div>
      <div class="meetingDuration">
        <p class="time">
          {{ moment(`${day} ${value.start}`).format("HH:mm") }} -
          {{
            moment(
              new Date(`${day} ${value.start}`).getTime() + 1800000
            ).format("HH:mm")
          }}
        </p>
      </div>
       <div>
        <p class="note-text"
          >NOTE: You can arrange a meeting with one person and tick one box to
          send a meeting request or you can tick multiple boxes for the same
          date and time to invite more than one person . If you click multiple
          boxes, you will arrange a group meeting with these attendees invited
          only. If you plan to have a one to one meeting with one person, please
          tick only one box for the same date and time and one attendee to
          invite.
        </p>
      </div>
      <div class="dropdowns">
        <div class="dropdownInputBox">
          <input type="text" v-model="search" placeholder="Name" />
          <i class="fa fa-search search-Icon" aria-hidden="true"></i>
        </div>
        <div class="dropdownInputBox">
          <input type="text" v-model="searchCompany" placeholder="Company" />
          <div>
            <i class="fa fa-search search-Icon" aria-hidden="true"></i>
          </div>
        </div>
      </div>
      <!-- <div class="tags"> temporary commented 
        <span
          v-for="{ id, name } in tags"
          :key="id"
          class="tagFieldSpan"
          :class="selectedTags.includes(name) && 'activeTagFieldSpan'"
        >
          <span @click="filterParticipantsByTags(name)">{{ name }}</span>
        </span>
      </div> -->
      <div class="participants-filter">
        <div
          v-for="item in filteringCategory"
          :key="item"
          :class="item === selectedFilterCategory && 'activeFilterCategory'"
          @click="filterByCategory(item)"
        >
          {{ item }}
        </div>
      </div>
      <div class="overflow">
        <div
          class="membersList"
          v-for="result in filteredUsers"
          :key="result.id"
        >
          <div
            class="spam"
            v-if="isMarkedAsSpam(result) || isMemberMarkedMeAsSpam(result)"
          >
            <b-form-checkbox disabled />
            <i class="fas fa-minus-circle"></i>
            <img :src="result.details.prof_pic" alt="" />
            <div class="info">
              <span class="userName">
                {{ `${result.details.name}` }}
                <i class="fas fa-user-friends"></i>
              </span>
              <span>{{ result.details.job_title }}</span>
              <span>{{ result.details.company }}</span>
            </div>
            <div v-if="isMarkedAsSpam(result)" class="unblock-spam">
              <span>Marked as spam</span>
              <button
                class="remove-spam-button"
                @click="removeFromSpamList(result)"
              >
                Unblock
              </button>
            </div>
          </div>
          <div
            v-else
            @click="selectUser(result)"
            :style="
              isUserSelected(result.details.customer_id) &&
              'background-color: #ebebeb'
            "
          >
            <b-form-checkbox
              name="terms"
              :checked="isUserSelected(result.details.customer_id)"
              @change="selectUser(result)"
            />
            <img :src="result.details.prof_pic" alt="" />
            <div>
              <span class="userName">
                {{ `${result.details.name}` }}
                <i class="fas fa-user-friends"></i>
              </span>
              <span>{{ result.details.job_title }}</span>
              <span>{{ result.details.company }}</span>
            </div>
          </div>
        </div>
         <infinite-loading @infinite="infiniteHandler">
        <div slot="no-more" />
        <div slot="no-results" />
        <div slot="spinner"></div>
      </infinite-loading>
      </div>

      <div class="footer">
        <span class="support">
          <a
            class="privacy-settings-support-text"
            href="mailto:info@carriercommunity.com?subject=Support&cc=Kara@carriercommunity.com;w.schmidt@carriercommunity.com"
          >
            Need support? Click here!
          </a>
        </span>
        <button
          :class="{
            'confirmButton-disabled': isDisabledButton,
            confirmButton: !isDisabledButton,
          }"
          :disabled="isDisabledButton"
          @click="showModal(false, timeData)"
        >
          Confirm
        </button>

        <button
          class="cancelButton"
          @click="confirmModalShow = !confirmModalShow"
        >
          Cancel
        </button>
      </div>
      <div>
        <AvailableSlotsModal
          :handleClose="handleClose"
          :selectedUsers="selectedUsers"
          :day="day"
          :timeData="timeData"
          :newData="newData"
          :users="users"
        />
      </div>
    </b-modal>
    <b-modal
      :id="`${timeData.start}${day}`"
      hide-footer
      @hidden="resetDurationData"
    >
      <form novalidate @submit.prevent="validateBeforeSubmit">
        <div>
          <div class="slot-day">
            <h4>
              {{ moment(day).format("dddd DD MMMM") }}
            </h4>
          </div>
          <div class="meetingDuration">
            <p class="time">
              {{
                moment(
                  `${day} ${$lodash.get(selectedSlots[0], "start")}`
                ).format("HH:mm")
              }}
              -
              {{
                moment(
                  `${day} ${$lodash.get(
                    selectedSlots[selectedSlots.length - 1],
                    "end"
                  )}`
                ).format("HH:mm")
              }}
            </p>
            <button
              @click="editDuration"
              :class="
                showEditDurationDiv
                  ? 'confirmButton-disabled'
                  : 'extendDuration'
              "
              :disabled="showEditDurationDiv && !isMobileScreen"
            >
              {{
                isMobileScreen && showEditDurationDiv
                  ? "Cancel"
                  : "Extend meeting duration"
              }}
            </button>
          </div>
          <div
            v-if="showEditDurationDiv && extendableSlots.length"
            class="extend-meeting-section"
          >
            <button
              v-for="(slot, index) in extendableSlots"
              :key="slot.id"
              :class="
                selectedSlotsIDs.includes(slot.id)
                  ? 'extend-meeting-button-selected'
                  : 'extend-meeting-button'
              "
              :disabled="
                index > 0 &&
                !selectedSlotsIDs.includes(
                  $lodash.get(extendableSlots[index - 1], 'id')
                )
              "
              @click="changeMeetingDuration(slot)"
            >
              {{ moment(`${day} ${slot.start}`).format("HH:mm") }} -
              {{ moment(`${day} ${slot.end}`).format("HH:mm") }}
              <div
                v-if="
                  (!selectedSlotsIDs.includes(slot.id) && index < 1) ||
                  (!selectedSlotsIDs.includes(slot.id) &&
                    selectedSlotsIDs.includes(
                      $lodash.get(extendableSlots[index - 1], 'id')
                    ))
                "
                class="add"
              >
                ADD
              </div>
              <div
                v-if="
                  (selectedSlotsIDs.includes(slot.id) && index < 1) ||
                  (selectedSlotsIDs.includes(slot.id) &&
                    selectedSlotsIDs.includes(
                      $lodash.get(extendableSlots[index - 1], 'id')
                    ))
                "
                class="remove"
              >
                REMOVE
              </div>
            </button>
          </div>
          <div
            v-else-if="showEditDurationDiv && !extendableSlots.length"
            class="extendable-slots-empty"
          >
            Next slot is not free
          </div>
          <div
            class="selectedMember forRequestMessageSingle"
            v-if="selectedUsers.length === 1"
          >
            <img
              :src="selectedUsers[0].details.prof_pic"
              alt="Member Picture"
            />
            <div>
              <span class="userName">
                {{ selectedUsers[0].details.name }}
              </span>
              <span class="userPosition">
                {{ selectedUsers[0].details.job_title }}
              </span>
              <span class="userCompany">
                {{ selectedUsers[0].details.company }}
              </span>
            </div>
          </div>
          <div v-else class="forMultipleRequest">
            <div
              class="selectedMember forRequestMessageSingle"
              v-for="user in selectedUsers"
              :key="user.id"
            >
              <img :src="user.details.prof_pic" alt="Member Picture" />
              <div>
                <span class="userName">
                  {{ user.details.name }}
                </span>
                <span class="userPosition">
                  {{ user.details.job_title }}
                </span>
                <span class="userCompany">
                  {{ user.details.company }}
                </span>
              </div>
            </div>
          </div>
          <article class="textareaField">
            <p>Meeting Request Message (max. 300 characters)*</p>
            <textarea
              v-model="form.message"
              name="Message"
              maxlength="300"
              rows="5"
              v-validate="{ required: true, max: 300 }"
            >
            </textarea>
            <span class="error" v-if="errors.has('Message')">
              {{ errors.first("Message") }}
            </span>
          </article>
          <div class="locationMeeting">
            <p>Meeting Location<span>*</span></p>
            <select
              v-model="form.location"
              name="Location"
              v-validate="{ required: true }"
            >
              <option value="" disabled selected>Select Location</option>
              <option value="Event Registration Desk">Event Registration Desk</option>
              <option value="other">Other</option>
            </select>
            <span class="error" v-if="errors.has('Location')">
                {{errors.first("Location")}}
             </span>
            <textarea
              v-if="form.location === 'other'"
              v-model="form.locationOther"
              name="LocationOther"
              class="mt-2"
              placeholder="Please specify a location"
              rows="1"
              v-validate="{ required: true }"
            />
            <span class="error" v-if="errors.has('LocationOther')">
              {{ errors.first("LocationOther") }}
            </span>
          </div>
          <div class="footer">
            <span class="supportforSecondModal">
              <a
                class="privacy-settings-support-text"
                href="mailto:info@carriercommunity.com?subject=Support&cc=Kara@carriercommunity.com;w.schmidt@carriercommunity.com"
              >
                Need support? Click here!
              </a>
            </span>
            <button class="send" @click.prevent="sendInvite">Send</button>
            <button class="confirmButton" @click="hideModal">Back</button>
          </div>
        </div>
      </form>
    </b-modal>
    <b-modal
      hide-footer
      :id="`invite-${timeData.start}${day}`"
      @hidden="handleClose"
    >
      <div class="congrat">
        <p>CONGRATULATIONS!</p>
        <p>Your Meeting request has been successfully sent.</p>
      </div>
      <div style="height: 7vh; width: 100%; border-top: 1px solid #eee"></div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import moment from 'moment';
import AvailableSlotsModal from './AvailableSlotsModal.vue';
import MPInvitationFunctional from '../../../../utils/mixins/MPInvitationFunctional';
import extendMeetingDuration from '../../../../utils/mixins/extendMeetingDuration';
import getIDfromURL from '../../../../utils/getIDfromURL';
import { getOwnerId } from '../../../../utils/getOwnerId';

export default {
  components: {
    AvailableSlotsModal,
  },
  mixins: [MPInvitationFunctional, extendMeetingDuration],
  props: {
    day: {
      type: String,
      default: '',
    },
    value: {
      type: Object,
      default: () => {},
    },
    timeData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      options: [],
      confirmModalShow: false,
      messageModalShow: false,
      congratModal: false,
      notAvalilableModal: false,
      isHidden: true,
      search: '',
      company: [],
      searchCompany: '',
      users: [],
      page: 1,
      hasNextPage: true,
      form: {
        message: '',
        location: '',
        locationOther: '',
      },
      error: null,
      availableData: null,
      newDays: {},
      filteredUsers: [],
      selectedTags: [],
      filteringCategory: [
        'All Attendees',
        'Attendees From My Network',
        'Attendees From My Company',
        'Spam',
      ],
      selectedFilterCategory: 'All Attendees',
    };
  },
  methods: {
    ...mapActions({
      fetchParticipantMembers: 'events/fetchParticipantMembers',
      searchNetworkUser: 'user/searchNetworkUser',
    }),
    openInvitationModal() {
      this.confirmModalShow = !this.confirmModalShow;
      this.selectedTags = [];
      this.company = [];
      this.search = '';
      this.selectedUsers = [];
      this.selectedFilterCategory = 'All Attendees';
    },
    async infiniteHandler($state) {
      try {
        if (this.hasNextPage) {
          const data = await this.fetchParticipantMembers({
            eventId: this.$route.params.eventId,
            searchName: this.search,
            page: this.page,
          });
          if (data.results.length) {
            this.page += 1;
            this.hasNextPage = !!data.next;
            const filteredData = data.results.filter(
              user => getOwnerId() !== user.details.customer_id,
            );
            this.users = [...this.users, ...filteredData];
            this.filteredUsers = [...this.users];
            $state.loaded();
          } else {
            $state.complete();
          }
        } else {
          $state.complete();
        }
      } catch (err) {
        $state.complete();
      }
    },
    filterParticipantsByTags(name) {
      let isMatch = false;
      if (this.selectedTags.includes(name)) {
        this.selectedTags.splice(this.selectedTags.indexOf(name), 1);
        if (this.selectedTags[0]) {
          const fUsers = this.users.filter(({ details: { segments } }) => {
            isMatch = false;
            segments.forEach((tag) => {
              if (this.selectedTags.includes(tag)) isMatch = true;
            });
            return isMatch;
          });
          this.filteredUsers = fUsers;
        } else {
          this.filteredUsers = [...this.users];
        }
      } else if (!this.selectedTags.includes(name)) {
        this.selectedTags.push(name);
        const fUsers = this.filteredUsers.filter(({ details: { segments } }) => segments.includes(name));
        this.filteredUsers = fUsers;
      }
    },
    async filterByCategory(item) {
      this.selectedTags = [];
      this.selectedFilterCategory = item;
      this.selectedUsers = [];
      switch (item) {
        case 'Attendees From My Network':
          await this.searchNetworkUser({
            customerId: this.userInfo.id,
            memberName: '',
            companyId: '',
          }).then(({ results }) => {
            const network = results.map(
              member => member.network_member_details.customer_id,
            );
            this.filteredUsers = this.users.filter(user => network.includes(user.details.customer_id));
          });
          break;
        case 'Attendees From My Company':
          this.filteredUsers = this.users.filter(
            user => user.details.company_id === getIDfromURL(this.userInfo.company),
          );
          break;
        case 'Spam':
          this.filteredUsers = this.users.filter(user => this.userInfo.spam_list.includes(user.details.customer_id));
          break;
        default:
          this.filteredUsers = [...this.users];
          break;
      }
    },
    async sendInvite() {
      const isValid = await this.$validator.validateAll();
      const {
        form: { message, location, locationOther },
      } = this;
      if (isValid) {
        const requestData = {
          invitor: this.userInfo.url,
          invited_customers: this.selectedUsers.map(user => user.details.url),
          message,
          location: location === 'other' ? locationOther : location,
          meeting_planner_slots: this.selectedSlots.map(
            ({ id }) => `${process.env.VUE_APP_API_HOST}/events/meeting-planner-slots/${id}/`,
          ),
        };
        try {
          await this.meetingPlannerInvitation(requestData);
          await this.fetchMeetingPlanner(this.event.id);
          this.$bvModal.hide(`available-modal-${this.availableSlotsModalId}`);
          this.$bvModal.hide(`${this.timeData.start}${this.day}`);
          this.$bvModal.show(`invite-${this.timeData.start}${this.day}`);
          this.newData.availability = null;
          this.filteredUsers = [...this.users];
          this.selectedTags = [];
        } catch (err) {
          err.response.data.forEach((text) => {
            this.$notify({
              group: 'userAlerts',
              title: 'Warning',
              text,
              type: 'warn',
            });
          });
        }
      }
    },
    async validateBeforeSubmit() {
      await this.$validator.validate();
    },
    hideModal() {
      this.$bvModal.hide(`${this.timeData.start}${this.day}`);
    },
    moment(date) {
      return moment(new Date(date).toUTCString());
    },
  },
};
</script>

<style src="./modals.css" scoped></style>
<style src="./SingleMemberInvitation.css" scoped></style>
<style src="./StylesForMobile.css" scoped></style>
<style>
#allModals #__BVID__43___BV_modal_content_ {
  width: 98% !important;
}
#allModals .modal-title {
  font-weight: 900;
  font-size: 22px;
}
#allModals .btn {
  font-size: 11px;
  border-radius: 0px;
  background-color: #bd9d5f;
  border: 1px solid #fff;
}
#allModals .multiselect__option {
  background: #ffffff !important;
  color: gray;
}
.multiselect--active {
  font-size: 12px !important;
}
.multiselect--active .multiselect__tags {
  height: 32px !important;
}
.multiselect__tags {
  height: 32px !important;
}
.multiselect--active .multiselect__input {
  font-size: 12px !important;
  padding: 0 !important;
  color: gray;
  margin-left: 4px !important;
}
.multiselect__single {
  color: gray;
  font-size: 12px;
  margin-bottom: 2px;
}
#allModals .multiselect__option:hover {
  color: #bd9d5f !important;
}
#allModals .btn-secondary {
  color: #fff;
}
#allModals .btn-primary {
  background-color: #ffffff;
  color: #bd9d5f;
  border: 1px solid #bd9d5f;
}
#allModals .btn-secondary:hover {
  background-color: #ffffff;
  color: #bd9d5f;
}
#allModals .btn-primary:hover {
  background-color: #bd9d5f;
  color: #ffffff;
}
#allModals .multiselect__tags {
  min-height: 0px !important;
  border-radius: 0px !important;
  padding: 4px;
  color: #ddd;
  border: none;
  border: 1px solid #aaa;
}
#allModals .multiselect__placeholder {
  font-size: 12px;
  margin-bottom: 0px;
  color: #7b7578;
}
#allModals .multiselect__option {
  background: #bd9d5f;
}
#allModals .modal-body {
  padding: 0;
}
.slot-day {
  position: relative;
  top: -44px;
  left: 13px;
  width: 88%;
  color: #333333;
}
.slot-day > h4 {
  font-size: 22px;
  text-transform: uppercase;
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: left;
}
.userInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 1%;
  width: 82%;
}

.company-logo {
  width: 40px;
}
.search-Icon {
  color: #a5a5a5;
  position: absolute;
  top: 7px;
  right: 12px;
}
.availableTimes {
  width: 80px;
  padding: 5px;
  background: #626262;
  color: white;
  cursor: pointer;
}
.singeRow > div > h3 {
  font-size: 13px;
}
.tagFieldSpan {
  cursor: pointer;
  transition-duration: 0.3s;
}
.tagFieldSpan:hover {
  background-color: #bd9d5f !important;
  color: #fff !important;
  border-radius: 2px;
}
.activeTagFieldSpan {
  background-color: #bd9d5f !important;
  border-radius: 2px;
}
</style>
