<template>
  <div id="participants" v-if="isActiveSection">
    <div class="participants-container">
      <div class="input-outter-group">
        <b-input-group size="sm" class="mb-2">
          <b-form-input
            v-model="search"
            id="search-field"
            type="search"
            placeholder="Search terms"
            debounce="400"
          ></b-form-input>
        </b-input-group>
        <div class="search-icon">
          <i class="fa fa-search" aria-hidden="true"></i>
        </div>
      </div>
      <!-- <div class="segments-div">  temporary commented 
        <b-badge
          v-for="{ id, name } in companyTags"
          :key="id"
          id="participants-tags"
          :class="selectedTags.includes(name)&& 'activeTagFieldSpan'"
          variant="dark"
          @click="search = name"

        >{{ name }}
        </b-badge>
      </div> -->
      <div class="selectBox">
        <b-form-select
          v-model="selected"
          @change="sortedCompanies"
          size="sm"
          class="mt-3"
        >
          <option :value="null" class="disabledOption">Sort By</option>
          <option :value="1">Name:A-Z</option>
          <option :value="-1">Name:Z-A</option>
        </b-form-select>
      </div>

      <b-container
        :class="['participants-photos', { 'increased-max-height': ['Companies', 'PublicCompanies'].includes($route.name)}]"
        v-if="event.display_participant_companies"
      >
        <!-- <b-row class="seven-cols">
          <b-col
            class="participant-img member-card"
            sm="1"
            v-for="participant in filteredCompanies"
            :key="participant.id"
          >
            <router-link
              :to="{
                name: 'companyProfile',
                params: { companyId: getURL(participant.company) },
              }"
            >
              <b-col class="participant-photo-container-vertical">
                <b-img
                  class="moderator-photo"
                  :src="participant.details.logo"
                  alt=""
                />
              </b-col>
            </router-link>
          </b-col>
        </b-row> -->
      <b-row no-gutters  class="seven-cols">
        <b-col
          sm="1"
          class="participant-col"
          v-for="participant in filteredCompanies"
              :key="participant.id"
        >
          <router-link
            :to="{
              name: 'companyProfile',
              params: { companyId: getURL(participant.company) },
            }"
          >
            <img class="participant-logo" :src="participant.details.logo" alt="" />
          </router-link>

        </b-col>
      </b-row>
      <infinite-loading @infinite="infiniteHandler">
        <div slot="no-more" />
        <div slot="no-results" />
         <div slot="spinner"></div>
      </infinite-loading>
      </b-container>
    </div>
  </div>
    <div v-else style="text-align: center; color: #BD9D5F">The section is not available now and coming soon...</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import getIDfromURL from '../../../utils/getIDfromURL';

export default {
  name: 'ParticipantCompanies',
  data() {
    return {
      selected: null,
      search: '',
      offset: 0,
      limit: 50,
      participantCompanies: [],
      companyTags: [],
      filteredParticipants: [],
      selectedTags: [],
    };
  },
  async mounted() {
    this.companyTags = await this.companySettingsSelects('areas_of_interest');
    await this.fetchTags();
  },
  methods: {
    ...mapActions({
      fetchParticipantCompanies: 'events/fetchParticipantCompanies',
      companySettingsSelects: 'auth/companySettingsSelects',
      fetchTags: 'tags/fetchList',
    }),
    async infiniteHandler($state) {
      const isPublicCompanies = ['Companies', 'PublicCompanies'].includes(this.$route.name);
      const offset = isPublicCompanies ? 0 : this.offset;
      const limit  = isPublicCompanies ? 9999 : this.limit;
      try {
        const data = await this.fetchParticipantCompanies({
          eventId: this.$route.params.eventId,
          searchName: this.search,
          offset: offset,
          limit: limit,
        });
        if (data.results.length) {
          this.offset += this.limit;
          this.participantCompanies = [...this.participantCompanies, ...data.results];
          if (isPublicCompanies) {
            $state.complete();
          } else {
            $state.loaded();
          }
        } else {
          $state.complete();
        }
      } catch (err) {
        $state.complete();
      }
    },
    getURL(url) {
      return getIDfromURL(url);
    },
    sortedCompanies(value) {
      const secondValue = value * -1;
      this.participantCompanies.sort((a, b) => (a.details.name > b.details.name ? value : secondValue));
    },

  },
  computed: {
    ...mapGetters({
      event: 'events/fetchEvent',
    }),
    isActiveSection() {
      return this.event && this.event.available_sections ? this.event.available_sections.includes('participants') : false;
    },
    filteredCompanies() {
      return this.participantCompanies
    },
  },
  watch: {
    search: {
      async handler(name) {
        let request = {};
        if (name) {
          request = {
            eventId: this.$route.params.eventId,
            searchName: name,
          };
        } else {
          this.offset = 0;
          request = {
            eventId: this.$route.params.eventId,
            offset: this.offset,
            limit: this.limit,
          };
        }
        const data = await this.fetchParticipantCompanies(request);
        this.participantCompanies = data.results;
        this.offset += this.limit;
      },
    },
  },
};
</script>
<style src="./Participants.css" scoped></style>
<style scoped>
.participant-col {
  margin: 4px;
  padding: 0px 8px;
  background-color: #fff;
  border-radius: 0 10px 10px 10px;
  overflow: hidden;
}
.participant-col > a {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.participant-col:hover {
  opacity: 0.6;
  transition: 0.3s;
}
.participant-logo {
  flex-basis: 150px;
  object-fit: scale-down;
}
</style>
