import { render, staticRenderFns } from "./ParticipantCompanies.vue?vue&type=template&id=0c449ca0&scoped=true&"
import script from "./ParticipantCompanies.vue?vue&type=script&lang=js&"
export * from "./ParticipantCompanies.vue?vue&type=script&lang=js&"
import style0 from "./Participants.css?vue&type=style&index=0&id=0c449ca0&scoped=true&lang=css&"
import style1 from "./ParticipantCompanies.vue?vue&type=style&index=1&id=0c449ca0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c449ca0",
  null
  
)

export default component.exports